// src/Maintenance.js
import React from 'react';
import '../Styles/Maintenance.css'

const Maintenance = () => {
  return (
    <div className="maintenance-container">
      <h1>We'll Be Back Soon!</h1>
      <p>Our website is currently undergoing maintenance. Please check back later.</p>
      <p>Thank you for your patience!</p>
    </div>
  );
};

export default Maintenance;
